<template id="report-share-modal">
  <ion-page>
    <ion-header>
      <ion-toolbar>
        <ion-title>{{ i18n.$t('report.share.title') }}</ion-title>
        <ion-buttons slot="end">
          <ion-button @click="closeModal()">{{ i18n.$t('default_interaction.cancel') }}</ion-button>
        </ion-buttons>
      </ion-toolbar>
    </ion-header>
    <ion-content>
      <div class="fixed-height-container">
        <ion-card class="document-selection-card"> <!-- TODO Make those cards maximum half height, or half width if horizontal! -->
          <ion-card-header>
            <ion-card-title>{{ i18n.$t('report.share.reports_to_share') }}</ion-card-title>
            <div class="editing-status-container">
              <!-- Use key to rerender when it changes to or from empty selection -->
              <ion-segment mode="ios" class="editing-status-selection" :key="(editingStatus == null) ? 'editing-empty' : 'editing-selected'" v-model="editingStatus">
                <ion-segment-button class="editing-status-button" :value="REORDER_STATUS">
                  <ion-label class="wrap">{{ i18n.$t('report.share.reorder') }}</ion-label>
                </ion-segment-button>
                <ion-segment-button class="editing-status-button" :value="EXCLUDE_STATUS">
                  <ion-label class="wrap">{{ i18n.$t('report.share.exclude') }}</ion-label>
                </ion-segment-button>
              </ion-segment>
              <ion-button :disabled="editingStatus == null" class="finish-editing-button" color="success" @click="resetEditingStatus" >
                <ion-icon slot="icon-only" :icon="checkmarkSharp"></ion-icon>
              </ion-button>
            </div>
          </ion-card-header>
          <ion-card-content class="scrollable-content">
            <ion-list class="outer-list">
              <ion-reorder-group :disabled="!(isReorderEnabled && (currentOrder != null) && (currentOrder.length > 1))" @ionItemReorder.stop="reorderConnectionOrSingleReport($event)">
                <ion-item class="outer-item" v-for="(connectionOrSingleReport, connectionOrSingleReportIndex) in currentOrder" :key="connectionOrSingleReportIndex">
                  <div class="connection-container">
                    <div class="connection-title">
                      <!-- Only enable reorder, when there is more than one element -->
                      <ion-reorder v-if="isReorderEnabled && (currentOrder.length > 1)"></ion-reorder>
                      <template v-else-if="isExcludeEnabled">
                        <ion-item v-if="connectionOrSingleReport.connection === true" class="checkbox-wrapper" lines="none"
                          @click="setIncludedReports(connectionOrSingleReport.reports, !(areSomeOrAllReportsIncluded(connectionOrSingleReport.reports)))">
                          <ion-checkbox class="exclude-checkbox" 
                            :checked="areSomeOrAllReportsIncluded(connectionOrSingleReport.reports)"
                            :indeterminate="areOnlySomeReportsIncluded(connectionOrSingleReport.reports)">
                          </ion-checkbox>
                        </ion-item>
                        <ion-item v-else class="checkbox-wrapper" lines="none" @click="setIncludedReport(connectionOrSingleReport, !(isReportIncluded(connectionOrSingleReport)))">
                          <ion-checkbox class="exclude-checkbox" 
                            :checked="isReportIncluded(connectionOrSingleReport)">
                          </ion-checkbox>
                        </ion-item>
                      </template>

                      <ion-label :class="isReportOrConnectionExcluded(connectionOrSingleReport) ? 'excluded-label' : undefined">
                        <!-- Use either the ID if it is a single report or the first reports ID of a connection to get a horse name. And not assigned as a fallback if null is returned -->
                        <h2><b>{{ getAnimalName((((connectionOrSingleReport.connection !== true) ? connectionOrSingleReport.id : null)) || getFirstItemOrEmptyObject(connectionOrSingleReport.reports).id) || i18n.$t('tracking.horse_not_assigned') }}</b></h2> <!-- TODO Split horse IDs here, before fetching to catch multiple horses -->
                        <h3>{{ timestampToDateTime(connectionOrSingleReport.timestamp) || i18n.$t('tracking.no_timestamp') }}</h3>
                        <template v-if="connectionOrSingleReport.connection !== true">
                            <h4>{{ getLocalizedMainCategory(connectionOrSingleReport.id) }}</h4>
                            <p class="wrap">{{ getLocalizedReportTypeName(connectionOrSingleReport.id) }}</p>
                        </template>
                      </ion-label>
                    </div>
                    <ion-list class="inner-list" v-if="connectionOrSingleReport.connection === true">
                      <ion-reorder-group :disabled="!(isReorderEnabled && (connectionOrSingleReport.reports != null) && (connectionOrSingleReport.reports.length > 1))" @ionItemReorder.stop="reorderReportInConnection($event, connectionOrSingleReport.id)">
                        <ion-item class="inner-item" v-for="(reportInConnection, reportInConnectionIndex) in connectionOrSingleReport.reports" :key="reportInConnectionIndex">
                          <!-- Only enable reorder, when there is more than one element -->
                          <ion-reorder v-if="isReorderEnabled && (connectionOrSingleReport.reports.length > 1)"></ion-reorder>
                          <ion-item v-else-if="isExcludeEnabled" class="checkbox-wrapper" lines="none"
                            @click="setIncludedReport(reportInConnection, !(isReportIncluded(reportInConnection)))">
                            <ion-checkbox class="exclude-checkbox"
                              :checked="isReportIncluded(reportInConnection)">
                            </ion-checkbox>
                          </ion-item>
                          <ion-label :class="isReportOrConnectionExcluded(reportInConnection) ? 'excluded-label' : undefined">
                            <h2>{{ getLocalizedMainCategory(reportInConnection.id) }}</h2>
                            <p class="wrap">{{ getLocalizedReportTypeName(reportInConnection.id) }}</p>
                          </ion-label>
                        </ion-item>
                      </ion-reorder-group>
                    </ion-list>
                  </div>
                </ion-item>
              </ion-reorder-group>
            </ion-list>
          </ion-card-content>
        </ion-card>
        <div class="finalize-buttons">
          <!-- TODO Disable while something is still loading -->
          <ion-button :disabled="currentReportIDsOrderedAndIncluded.length <= 0" @click="openFileExportModal()">
            <ion-icon slot="start" :icon="documentTextOutline"></ion-icon>{{ i18n.$t('report.share.pdf') }}
          </ion-button>
          <!--<ion-button  :disabled="currentReportIDsOrderedAndIncluded.length <= 0">
            <ion-icon slot="start" :icon="shareOutline"></ion-icon>{{ i18n.$t('report.share.internal') }}
          </ion-button>-->
        </div>
      </div>
    </ion-content> <!-- TODO Add loading indicator somewhere. Maybe for each report in the list to set its hidden fields etc. Add a loading spinner and make item not clickable while it is loading. Also don't show horse name and other properties yet, if not loaded -->
  </ion-page>
</template>

<!-- FIXME Loading spinner hat komischen Margin unten. Margin unter der Auswahlliste passt in iOS nicht -->

<script>

import { IonPage, IonHeader, IonToolbar, IonTitle, IonButtons, IonButton, IonContent, IonCard, IonCardHeader, IonCardTitle, IonCardContent, IonList, IonItem, IonLabel, IonReorderGroup, IonReorder, IonIcon, IonSegment, IonSegmentButton, IonCheckbox, modalController } from '@ionic/vue';
import { defineComponent, computed, ref, watch } from 'vue';
import { useStore } from 'vuex';

import _ from 'lodash';

import { documentTextOutline, shareOutline, checkmarkSharp } from 'ionicons/icons';

import { useI18n } from "@/utils/i18n";

import { useDayjs } from '@/utils/dayjs';

import { apiErrorToast } from '@/utils/error';

import { METADATA_SHORT_KEY_MAPPING, REPORT_PROPERTY_MAPPINGS } from '@/utils/report';

import { openReportExportModal, default as reportExportModalComponent } from '@/components/ReportExportModal.vue';

const ReportShareModal = defineComponent({
  name: 'ReportShareModal',
  components: { IonPage, IonHeader, IonToolbar, IonTitle, IonButtons, IonButton, IonContent, IonCard, IonCardHeader, IonCardTitle, IonCardContent, IonList, IonItem, IonLabel, IonReorderGroup, IonReorder, IonIcon, IonSegment, IonSegmentButton, IonCheckbox },
  props: {
    'reportIDs': Array,
    'preLoadedReportProperties': Object,
    'preLoadedReportMetadata': Object,
    'preLoadedReportsData': Object
  },
  setup(props) {
    const store = useStore();
    
    const i18n = useI18n();

    const { dayjs } = useDayjs();

    const closeModal = function(){
      modalController.dismiss();
    }

    //Map the report index to an array
    const reportIndexMetadata = computed(() => {
      if (props.reportIDs == null || !Array.isArray(props.reportIDs) || props.reportIDs.length <= 0) return [];
      let indexMetadataGetter = store.getters['reports/getReportMetadataById'];
      return _.map(props.reportIDs, (id) => {return { id, ...indexMetadataGetter(id)}});
    });

    //Stores the loaded data by ID, order is given separately
    const loadedReportsData = ref({});

    //Combines the pre-loaded data and the new loaded data
    const combinedLoadedReportsData = computed(() => {
      return _.assign({}, props.preLoadedReportsData, loadedReportsData.value);
    });

    //Keeps an array of all excluded report IDs
    const excludedReports = ref([]);

    //Returns true if some or all of the given reports do not exist in the exclusion array
    const areSomeOrAllReportsIncluded = computed(() => {
      return function(reportObjectsWithID) {
        let reportIDs = _.map(reportObjectsWithID, 'id');
        //Get all the reports with the excluded reports removed, Use non-strict equality for strings and numbers to be equal
        let includedReports = _.differenceWith(reportIDs, excludedReports.value, ((a, b) => (a == b)));
        //If there are still some included reports left, return true
        if (includedReports.length > 0) return true;
        //Otherwise false
        return false;
      }
    });

    //Returns true if some of the given reports do not exist in the exclusion array, but false if all of them do not exist in excludedReports
    const areOnlySomeReportsIncluded = computed(() => {
      return function(reportObjectsWithID) {
        let reportIDs = _.map(reportObjectsWithID, 'id');
        //Get all the reports with the excluded reports removed, Use non-strict equality for strings and numbers to be equal
        let includedReports = _.differenceWith(reportIDs, excludedReports.value, ((a, b) => (a == b)));
        //If none got removed, all reports are still visible, so return false
        if (includedReports.length == reportIDs.length) return false;
        //If there are still some included reports left, but less than all, return true
        if (includedReports.length > 0) return true;
        //Otherwise false
        return false;
      }
    });

    //Helper function for single report
    const isReportIncluded = computed(() => {
      return function(reportObjectWithID) {
        if (reportObjectWithID != null) {
          return areSomeOrAllReportsIncluded.value([reportObjectWithID]);
        }
      }
    });

    //Set the new inclusion status of the given report IDs
    const setIncludedReports = computed(() => {
      return function(reportObjectsWithID, include) {
        let reportIDs = _.map(reportObjectsWithID, 'id');
        if (include == true) {
          //If the given reports should be included again, remove from the array by taking the difference
          excludedReports.value = _.differenceWith(excludedReports.value, reportIDs, ((a, b) => (a == b)));
        } else {
          //Otherwise add them to the array using union to exclude them
          excludedReports.value = _.unionWith(excludedReports.value, reportIDs, ((a, b) => (a == b)));
        }
      }
    });

    //Helper function for single report
    const setIncludedReport = computed(() => {
      return function(reportObjectWithID, include) {
        if (reportObjectWithID != null) {
          return setIncludedReports.value([reportObjectWithID], include);
        }
      }
    });

    //Returns true if the report or all the reports inside the connection are excluded. Uses connection parameter to determine if it is a connection or a single report.
    const isReportOrConnectionExcluded = computed(() => {
      return function(connectionOrSingleReport) {
        if (connectionOrSingleReport != null) {
          if (connectionOrSingleReport.connection === true) {
            return !(areSomeOrAllReportsIncluded.value(connectionOrSingleReport.reports));
          } else {
            return !(areSomeOrAllReportsIncluded.value([connectionOrSingleReport]));
          }
        }
      }
    });

    //Only fetch if not already fetched!
    const fetchReport = async function(id) {
      if (id in loadedReportsData.value) return null;
      return store.dispatch('reports/fetchReport', id);
    }

    //Load all the report IDs for which no preLoadedReportsData was given
    watch([() => props.reportIDs, () => props.preLoadedReportsData], ([newReportIDs, newPreLoadedReportsData]) => {
      if (newReportIDs != null && Array.isArray(newReportIDs)) {
        //Use all IDs not included in the preLoadedIDs. Use non-strict equality for strings and numbers to be equal
        let notLoadedReportIDs = _.differenceWith(newReportIDs, Object.keys(newPreLoadedReportsData), ((a, b) => (a == b)));

        //Fetch all of those reports by mapping each report id to the promise of fetchReport and wait for all with Promise.all
        Promise.all(_.map(notLoadedReportIDs, (reportId) => fetchReport(reportId)))
          .then((reportArray) => {
            //Remove null values from those that are not refetched, because they had been fetched already!
            loadedReportsData.value = _.keyBy(_.map(_.compact(reportArray), 'data'), (report) => report['id']);
          })
          .catch((error) => {
            apiErrorToast(i18n, error, true) //TODO Test that it gets caught
              .then(() => closeModal());
          });
      }
    }, { immediate: true });

    //Sorts all reports into a hierarchical array of single reports and connections
    //Connected reports are always grouped, no matter if they are next to each other in the array
    //Later reports end up at the position of the first report with that connection
    //If multiple connections are given, the first entry is used for grouping
    //Single reports and connecions are interleaved in the same order as they were originally
    const originalOrder = computed(() => {
      //Array with either single reports or connections containing the reports of it in an array
      let orderedEntries = [];
      //Object to lookup the array index
      let connectionArrayLookup = {};
      
      for (let reportMetadata of reportIndexMetadata.value) {
        //If there are any connections, use them to group the report
        let connections = reportMetadata[METADATA_SHORT_KEY_MAPPING['connections']];
        if (connections != null && Array.isArray(connections) && connections.length > 0) {
          //Use first connection for grouping
          let firstConnection = connections[0];
          if (!(firstConnection in connectionArrayLookup)) {
            //If it is not yet in the lookup add it with the next index of the array
            connectionArrayLookup[firstConnection] = orderedEntries.length;
            //Push it into the array at the new index, with the timestamp of this report
            orderedEntries.push({'id': firstConnection, 'timestamp': reportMetadata[METADATA_SHORT_KEY_MAPPING['timestamp']], 'connection': true, 'reports': []});
          }
          //Push the current report ID to the report array of this connection
          orderedEntries[connectionArrayLookup[firstConnection]]['reports'].push({'id': reportMetadata['id']});
        } else { //Otherwise just add this report ID as a single report
          orderedEntries.push({'id': reportMetadata['id'], 'timestamp': reportMetadata[METADATA_SHORT_KEY_MAPPING['timestamp']]});
        }
      }

      return orderedEntries;
    });

    const modifiedOrder = ref(null);

    //Return the first non-null order, starting with the modified one
    const currentOrder = computed(() => {
      if (modifiedOrder.value != null) return modifiedOrder.value;
      return originalOrder.value;
    });

    //Create a list again out of the current order with the excluded ones removed
    const currentReportIDsOrderedAndIncluded = computed(() => {
      //Create a flat array out of all the report IDs in the hierarchical order object
      let orderedReportIDs = _.flatMap(currentOrder.value, (connectionOrSingleReport) => {
        //For connections return all the reports within mapped to their IDs
        if (connectionOrSingleReport.connection == true) {
          return _.map(connectionOrSingleReport.reports, 'id');
        }
        //For single reports return the report id in an array for flattening
        return [connectionOrSingleReport.id];
      });

      //Return all the ordered report IDs without the ones that are excluded!
      return _.differenceWith(orderedReportIDs, excludedReports.value, ((a, b) => (a == b)));
    });

    //Returns first item of array or an empty object if not found
    const getFirstItemOrEmptyObject = function(array) {
      return _.get(array, [0], {});
    }

    //Helper to get a property for a loaded report or from the preLoadedReportProperties as fallback, if it is set
    const getProperty = function(reportId, propertyPath) {
      let value = _.get(combinedLoadedReportsData.value, [reportId].concat(propertyPath), null);
      //If not found in loadedReportsData, use preLoadedReportProperties as fallback if it is set
      if (value == null && props.preLoadedReportProperties != null) {
        value = _.get(props.preLoadedReportProperties, propertyPath, null);
      }

      return value;
    }

    const timestampToDateTime = computed(() => {
      return (timestamp) => {
        if (timestamp == null) return null;
        try {
          //Use js internal Date object for parsing the ISO String to treat 0-padded years correctly, e.g. 0001 is not 1901!
          let localDateTime = dayjs((new Date(timestamp))).millisecond(0).second(0); //Remove precision of seconds and milliseconds!
          if (localDateTime != null && localDateTime.isValid()) {
            return localDateTime.format('LLL'); //TODO Test if local time format is still changed on language change! If not fix here, ViewReport, ViewFormItem and Tracking.
          }
        } catch {
          //Skip conversion to date on error!
          return null;
        }
      }
    });

    const getAnimalName = function(reportId){
      let animalId = getProperty(reportId, [REPORT_PROPERTY_MAPPINGS['animal']]);
      if (animalId == null) return null;

      let animal = store.getters['horses/getHorseById'](animalId);
      if (animal) {
        return animal.name;
      } else {
        return null;
      }
    }

    const getLocalizedMainCategoryById = function(mainCategoryId){
      let categoryNames = store.getters['reports/getMainCategoryNamesById'](mainCategoryId);
      if (categoryNames) {
        return categoryNames[i18n.locale.value];
      } else {
        return null;
      }
    }

    const getLocalizedMainCategory = function(reportId){
      let mainCategoryId = getProperty(reportId, ['type', 'main_category']);
      if (mainCategoryId == null) return null;
      return getLocalizedMainCategoryById(mainCategoryId);
    }

    const getLocalizedReportTypeName = function(reportId){
      let reportTypeDescriptor = getProperty(reportId, ['type', 'descriptor']);
      if (reportTypeDescriptor == null) return null;
      return reportTypeDescriptor; //TODO Use the translated name here. Translate in API definition in reports too!
    }

    const reorderConnectionOrSingleReport = function(event) {
      let newOrder = _.cloneDeep(currentOrder.value);
      //Reorder in place in the cloned order
      newOrder = event.detail.complete(newOrder);
      //Set the changed order as modifiedOrder
      modifiedOrder.value = newOrder;
    }

    const reorderReportInConnection = function(event, connectionId) {
      //Only continue if the connection is valid
      if (connectionId != null) {
        let connectionIndex = _.findIndex(currentOrder.value, { 'id': connectionId, 'connection': true });

        //Only continue if the index can be found
        if (connectionIndex >= 0 && currentOrder.value[connectionIndex].reports != null) {
          let newOrder = _.cloneDeep(currentOrder.value);
          //Reorder in place in the cloned order
          newOrder[connectionIndex].reports = event.detail.complete(newOrder[connectionIndex].reports);
          //Set the changed order as modifiedOrder
          modifiedOrder.value = newOrder;
        }
      }

      //Otherwise reject the reorder, because the object to reorder can't be found
      return event.detail.complete(false);
    }

    const REORDER_STATUS = 'reorder';
    const EXCLUDE_STATUS = 'exclude';
    const editingStatus = ref(null);

    //Resets the editing status
    const resetEditingStatus = function() {
      editingStatus.value = null;
    }

    //Sets the editing status if it changed, otherwise reset
    const setEditingStatus = function(newStatus) {
      if (editingStatus.value == newStatus) editingStatus.value = null;
      else editingStatus.value = newStatus;
    }

    const isReorderEnabled = computed(() => {
      return editingStatus.value == REORDER_STATUS;
    });

    const isExcludeEnabled = computed(() => {
      return editingStatus.value == EXCLUDE_STATUS;
    });

    const openFileExportModal = function() {
      //TODO Close this modal, before opening the next one?
      openReportExportModal(reportExportModalComponent, currentReportIDsOrderedAndIncluded.value, props.preLoadedReportProperties, props.preLoadedReportMetadata, combinedLoadedReportsData.value);
    }

    return { 
      i18n,
      reportIndexMetadata,
      originalOrder,
      currentOrder,
      currentReportIDsOrderedAndIncluded,
      excludedReports,
      areSomeOrAllReportsIncluded,
      areOnlySomeReportsIncluded,
      isReportIncluded,
      setIncludedReport,
      setIncludedReports,
      isReportOrConnectionExcluded,
      timestampToDateTime,
      getAnimalName,
      getLocalizedMainCategory,
      getLocalizedReportTypeName,
      reorderConnectionOrSingleReport,
      reorderReportInConnection,
      getFirstItemOrEmptyObject,
      REORDER_STATUS,
      EXCLUDE_STATUS,
      editingStatus,
      resetEditingStatus,
      setEditingStatus,
      isReorderEnabled,
      isExcludeEnabled,
      closeModal,
      openFileExportModal,
      documentTextOutline,
      shareOutline,
      checkmarkSharp
    };
  }
});

//preLoadedReportData can contain report data that has been already fetched, so they don't have to be fetched again, if they are included in reportIDs
//Same for preLoadedReportProperties and preLoadedReportMetadata. If they are included, they are applied for every report that doesn't have those properties on its own when creating a PDF!
export async function openReportShareModal(component, reportIDs, preLoadedReportProperties, preLoadedReportMetadata, preLoadedReportsData = {}){
  if (component != null && reportIDs != null && Array.isArray(reportIDs) && reportIDs.length > 0) {
    const modal = await modalController
      .create({
        component,
        componentProps: {
          reportIDs,
          preLoadedReportProperties,
          preLoadedReportMetadata,
          preLoadedReportsData
        },
      })
    modal.present();
    return modal.onWillDismiss();
  }
}

export default ReportShareModal;
</script>

<style scoped>
ion-content {
  --padding-top: 10px;
  --padding-bottom: 10px;
}

ion-item {
  --background: var(--ion-card-background, #fff);
}

.editing-status-container {
  margin-top: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.finish-editing-button {
  margin: 1px 0px 1px 10px;
  --paddding-top: 0px;
  --padding-start: 8px;
  --padding-end: 8px;
  height: 32px;
}

.editing-status-selection {
  --background: var(--ion-color-medium-light);
}

.editing-status-button {
  --indicator-color: var(--ion-color-secondary);
  --color: var(--ion-color-medium-light-contrast);
  
  --border-color: var(--ion-color-medium);
  --color-checked: var(--ion-color-secondary-contrast);
}

.editing-status-button::part(native) {
  opacity: 1!important;
}

.fixed-height-container {
  display: flex;
  flex-direction: column;
  max-height: 100%;
}

.outer-list {
  padding: 0px;
}

.document-selection-card {
  margin-top: 0px;
  margin-bottom: 0px;
  margin-left: 12px;
  margin-right: 12px;
  position: relative;
  display: flex;
  flex-direction: column;
  flex-grow: 50;
}

.scrollable-content {
  max-height: 100%;
  overflow-y: scroll;
  padding-top: 0px;
  padding-bottom: 0px;
}

.finalize-buttons {
  padding: 4px 12px;
  display: flex;
  width: 100%;
  max-width: 100%;
  flex-wrap: wrap;
}

.finalize-buttons ion-button {
  flex-grow: 1;
  flex-basis: 0;
  text-transform: none;
}

.outer-item, .inner-item {
  --padding-start: 0px;
}

.outer-item > ion-label, .inner-item > ion-label, .connection-title > ion-label {
  margin-inline-start: 10px;
}

.inner-list {
  margin-left: 36px;
}

.inner-list .inner-item {
  --border-width: 0px 0px 0px 1px;
}

.outer-item:last-of-type, .inner-item:last-of-type {
  --inner-border-width: 0px 0px 0px 0px;
}

.connection-title {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-top: 10px;
  padding-bottom: 10px;
}

.wrap {
  text-overflow: unset;
  height: auto;
  white-space: normal;
}

.checkbox-wrapper {
  --ripple-color: transparent;
  --padding-start: 0px;
  --inner-padding-end: 0px;
  --padding-top: 0px;
  --padding-bottom: 0px;
  --min-height: 10px;
}

.checkbox-wrapper ion-checkbox {
  margin: 10px;
}

.excluded-label {
  text-decoration: line-through;
  opacity: 0.4;
}
</style>