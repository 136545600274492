<template id="report-export-modal">
  <ion-page>
    <ion-header>
      <ion-toolbar>
        <ion-title>{{ i18n.$t('report.export.title') }}</ion-title>
        <ion-buttons slot="end">
          <ion-button @click="closeModal()">{{ i18n.$t('default_interaction.close') }}</ion-button>
        </ion-buttons>
      </ion-toolbar>
    </ion-header>

    <ion-content>
      <ion-card v-if="reportsContainUpdatedOnes" color="warning" class="information-container">
        <ion-icon :icon="informationCircleOutline" size="large"></ion-icon>
        <div>{{ i18n.$t('report.export.no_link_hint_updated') }}</div>
      </ion-card>
      <ion-card>
        <ion-card-header>
          <ion-card-title>{{ i18n.$t('report.export.export_files') }}</ion-card-title>
        </ion-card-header>
        <ion-card-content class="download-button-container">
          <ProgressButton class="file-button main-button" @click="downloadPDF()" color="success" :loading="isProcessingPDF" :disabled="isProcessingAnyFile">{{ i18n.$t('report.export.download_pdf') }}</ProgressButton>
          <ProgressButton class="file-button main-button" @click="downloadZIP()" color="success" :loading="isProcessingZIP" :disabled="isProcessingAnyFile" v-if="anyReportsHaveFiles">{{ i18n.$t('report.export.download_zip') }}</ProgressButton>
        </ion-card-content>
      </ion-card>
      <div class="single-files-header" v-if="anyReportsHaveFiles">
        <h3>{{ i18n.$t('report.export.single_files') }}</h3>
      </div>
      <ion-card v-for="(fileArray, reportID) in reportFiles" :key="reportID">
        <ion-card-header>
          <ion-card-subtitle>{{ getLocalizedReportTypeName(reportID) }}</ion-card-subtitle>
          <ion-card-title>{{ getAnimalName(reportID) }} | {{ getReportTimestampString(reportID) }}</ion-card-title>
        </ion-card-header>
        <ion-card-content class="download-button-container">
          <ProgressButton class="file-button" v-for="(fileObject, fileIndex) in fileArray" :key="fileIndex"
            :loading="processingFiles[`${reportID}.${fileIndex}`]"
            :disabled="isProcessingAnyFile"
            @click="downloadFile(reportID, fileObject, `${reportID}.${fileIndex}`)">
              {{ fileObject.filename }}{{ fileObject.extension }}
          </ProgressButton>
        </ion-card-content> <!-- TODO Add warning again for uploading ones??!! -->
      </ion-card>
    </ion-content>
  </ion-page>
</template>

<script>

import { IonPage, IonHeader, IonToolbar, IonTitle, IonButtons, IonButton, IonContent, IonCard, IonCardHeader, IonCardTitle, IonCardSubtitle, IonCardContent, IonIcon, modalController, getPlatforms } from '@ionic/vue';
import { defineComponent, ref, computed } from 'vue';

import { useStore } from 'vuex';

import { informationCircleOutline } from 'ionicons/icons';

import _ from 'lodash';

import ProgressButton from '@/components/ProgressButton.vue';

import { useDayjs } from '@/utils/dayjs';

import { useI18n } from "@/utils/i18n";

import { useQR } from '@/components/composables/QR';

import PdfReport from "@/utils/pdf_report";

import { REPORT_PROPERTY_MAPPINGS, ADDITIONAL_COMMENT_FIELD, UNCATEGORIZED_CATEGORY, parseKey, typeDefinitionToHierarchicalObject, reportFieldsToHierarchicalObject, localizeSingleField, applyFunctionsToHierarchicalReportFields } from '@/utils/report';

import JSZip from 'jszip';

import { download, FILE_EXTENSION_MAPPING, sanitizeFileOrFolderName } from '@/utils/file_download';

const ReportExportModal = defineComponent({
  name: 'ReportExportModal',
  components: { IonPage, IonHeader, IonToolbar, IonTitle, IonButtons, IonButton, IonContent, IonCard, IonCardHeader, IonCardTitle, IonCardSubtitle, IonCardContent, IonIcon, ProgressButton },
  props: {
    'reportIDs': Array,
    'reportProperties': Object,
    'reportMetadata': Object,
    'reportsData': Object
  },
  setup(props) {
    const store = useStore();
    
    const i18n = useI18n();

    const qrInstance = useQR();

    const { dayjs } = useDayjs();

    const now = dayjs((new Date())).format('LLL');

    const pdf = ref(null);
    const pdfFileName = ref('Document'); //Default fallback filename
    const pdfBlob = ref(null);

    const zip = ref(null); //Contains a folder for each report and there in all files
    const zipFileName = ref('Files'); //Default fallback filename
    const zipBlob = ref(null);

    const isProcessingPDF = ref(false);

    const isProcessingZIP = ref(false);

    const processingFiles = ref({});

    const reportFiles = computed(() => {
      //Map each report to only include the array of file fields
      let reports = _.mapValues(props.reportsData, (reportData) => {
        //Process all the files to get consistent filenames. Keep only the fields with files left (not null).
        let files = _.compact(_.map(reportData.fields, (field) => {
          //Add all files of this fields to an array for easier processing
          let fileArray = [];
          if (field.files != null) {
            fileArray = fileArray.concat(field.files);
          }
          if (field.file != null) {
            fileArray.push(field.file);
          }

          //If no files, return null
          if (fileArray.length <= 0) return null;

          //Map every file to a new object with filename, extension and other necessary metadata set in the correct format
          let multipleFilesIndex = 1;
          return _.map(fileArray, (fileObject) => {
            //Parse the mime type to get a fallback extension
            let mimeFileType = (fileObject.mime != null) ? fileObject.mime.split('/').pop() : '';
            let mappedMimeFileType = FILE_EXTENSION_MAPPING[mimeFileType] || mimeFileType; //Try to map if mapping exists, or use as is
            let mimeFileExtension = (mappedMimeFileType.length) ? `.${mappedMimeFileType}` : '.dat'; //Get the last part of the mime as a backup in case we have no extension!

            //Create a new file with the parsed metadata
            let tmpFile = {
              filename: sanitizeFileOrFolderName(parseKey(field.key).filter((key) => (key != 'uncategorized')).join(' - '), '_'), //Take all parts, except uncategorized, for the filename
              mime: fileObject.mime,
              extension: (fileObject.ext != null && fileObject.ext.length) ? fileObject.ext : mimeFileExtension,
              blobURL: fileObject.blobURL
            };

            //If more than one file with this key will be added, append a number
            if (fileArray.length > 1){
              tmpFile.filename += ` (${multipleFilesIndex})`;
              multipleFilesIndex++;
            }

            return tmpFile;
          });
        }));

        //Return a flattened array of all files
        return _.flatten(files);
      });

      //Get only the reports with files left after filtering
      return _.pickBy(reports, (fileArray) => fileArray != null && fileArray.length > 0);
    });

    const anyReportsHaveFiles = computed(() => {
      return (reportFiles.value != null && (Object.keys(reportFiles.value).length > 0));
    });

    const isProcessingAnyFile = computed(() => {
      return (isProcessingPDF.value || isProcessingZIP.value || (Object.keys(processingFiles.value).length > 0));
    });

    const reportsContainUpdatedOnes = computed(() => { //TODO Implement updated checks again!
      return false;
    });

    const closeModal = function(){
      modalController.dismiss();
    }

    const getReportDescriptor = computed(() => {
      return (reportID) => {
        return `${getAnimalName(reportID)} - ${getReportTimestampString.value(reportID)} - ${getLocalizedReportTypeName(reportID)}`
      }
    });

    //Helper to get a property for a loaded report or from the reportProperties as fallback, if it is set
    const getProperty = function(reportId, propertyPath) {
      let value = _.get(props.reportsData, [reportId].concat(propertyPath), null);
      //If not found in loadedReportsData, use reportProperties as fallback if it is set
      if (value == null && props.reportProperties != null) {
        value = _.get(props.reportProperties, propertyPath, null);
      }

      return value;
    }

    const timestampToDateTime = computed(() => {
      return (timestamp) => {
        if (timestamp == null) return null;
        try {
          //Use js internal Date object for parsing the ISO String to treat 0-padded years correctly, e.g. 0001 is not 1901!
          let localDateTime = dayjs((new Date(timestamp))).millisecond(0).second(0); //Remove precision of seconds and milliseconds!
          if (localDateTime != null && localDateTime.isValid()) {
            return localDateTime.format('LLL'); //TODO Test if local time format is still changed on language change! If not fix here, ViewReport, ViewFormItem and Tracking.
          }
        } catch {
          //Skip conversion to date on error!
          return null;
        }
      }
    });

    const getReportTimestampString = computed(() => {
      return (reportId, timestampValue) => {
        //Use given value or get if not given
        let timestamp = (timestampValue != null) ? timestampValue : getProperty(reportId, [REPORT_PROPERTY_MAPPINGS['timestamp']]);
        
        return timestampToDateTime.value(timestamp);
      }
    });

    const getAnimalName = function(reportId, animalIdValue){
      //Use given value or get if not given
      let animalId = (animalIdValue != null) ? animalIdValue : getProperty(reportId, [REPORT_PROPERTY_MAPPINGS['animal']]);
      if (animalId == null) return null;

      let animal = store.getters['horses/getHorseById'](animalId);
      if (animal) {
        return animal.name;
      } else {
        return null;
      }
    }

    const getLocalizedReportTypeName = function(reportId, reportTypeDescriptorValue){
      //Use given value or get if not given
      let reportTypeDescriptor = (reportTypeDescriptorValue != null) ? reportTypeDescriptorValue : getProperty(reportId, ['type', 'descriptor']);
      if (reportTypeDescriptor == null) return null;
      return reportTypeDescriptor; //TODO Use the translated name here. Translate in API definition in reports too!
    }

    const downloadFile = computed(() => {
      return (reportID, fileObject, processingId) => {
       let reportDescriptor = getReportDescriptor.value(reportID);
        processingFiles.value[processingId] = true;
        fetch(fileObject.blobURL)
          .then(fetchResult => fetchResult.blob())
          .then((blob) => download(i18n, sanitizeFileOrFolderName(reportDescriptor + ' - ' + fileObject.filename + fileObject.extension, '_'), blob, blob.type, getPlatforms()))
          .catch(() => {
            return; //TODO No error handling?
          })
          .finally(() => delete processingFiles.value[processingId]);
      }
    });

    const createZIP = computed(() => {
      return async function(){
        //Only create if not already created
        if (reportFiles.value != null && zip.value == null && zipBlob.value == null) {
          zipFileName.value = `${i18n.$t('report.export.default_filename_multiple_reports')} ${now}`; //TODO RENAME THAT MULTIPLE REPORTS TEXT

          zip.value = new JSZip();

          for (let [reportID, files] of Object.entries(reportFiles.value)) {
            //If valid files are present, add the folder and the files within
            if (files != null && Array.isArray(files) && files.length > 0) {
              //Folder is the report descriptor
              let folder = zip.value.folder(sanitizeFileOrFolderName(getReportDescriptor.value(reportID), '_'));
              for (let file of files) {
                //Fetch and add file
                folder.file(sanitizeFileOrFolderName(file.filename + file.extension, '_'), fetch(file.blobURL).then(fetchResult => fetchResult.blob())); //TODO Show errors everywhere here when fetching. Maybe make a util function for fetching with error handling in case file is missing! Don't add if missing to avoid confusion!
              }
            }
          }

          //Generate and wait to always return the current zip below, even if already generated in the past
          zipBlob.value = await zip.value.generateAsync({type: 'blob', compression: 'DEFLATE'});
        }

        return zipBlob.value;
      }
    });

    const createPDF = async function(){
      //Only create if not already created
      if (props.reportIDs != null && pdf.value == null && pdfBlob.value == null) {
        pdfFileName.value = `${i18n.$t('report.export.default_filename_multiple_reports')} ${now}`;
        for (let reportID of props.reportIDs) { //TODO Merge connected reports to appear on one page somehow. And also set a header for each single report to identify the examination then!
          let reportData = (reportID in props.reportsData) ? props.reportsData[reportID] : {};
          let report = _.assign({}, props.reportProperties, reportData);

          //Localize report metadata
          report.title = getLocalizedReportTypeName(reportID, _.get(report, ['type', 'descriptor'], undefined));
          report.time = getReportTimestampString.value(reportID, report[REPORT_PROPERTY_MAPPINGS['timestamp']]);
          report.animal = getAnimalName(reportID, report[REPORT_PROPERTY_MAPPINGS['animal']]);
          report.location = i18n.$t(`report.location.${report[REPORT_PROPERTY_MAPPINGS['location']]}`);

          let definition = _.get(report, 'type.definition');
          //Transform fields to a hierarchical object in the same format as report definition
          let [fields] = reportFieldsToHierarchicalObject(report.fields, true);

          //If the report contains a valid definition, use that to set the definitions metadata to each field
          if (definition != null) {
            definition = typeDefinitionToHierarchicalObject(definition);
            //Apply properties of the definition to the fields to add additional metadata and always use the type from the definition
            fields = _.merge(fields, definition);
          }          

          //Use mapping functions to localize the report fields
          fields = applyFunctionsToHierarchicalReportFields(fields, {
            mapCategory: (categoryName, category) => {
              return {
                ...category,
                display_name: (categoryName == UNCATEGORIZED_CATEGORY) ? undefined : categoryName //Exclude uncategorized to not show it //TODO Could be translated here, if not already translated before. Create a util function for translating!
              }
            },
            mapItem: (itemName, item, categoryChain) => {
              let localizedItemName = itemName;
              //Use localized string for additional comment key
              if (itemName == ADDITIONAL_COMMENT_FIELD) {
                //Get the first level category. Uncategorized means the whole report
                if ((categoryChain != null) && (categoryChain[0] == UNCATEGORIZED_CATEGORY)) {
                  localizedItemName = i18n.$t('report.create.additional_comments_report');
                } else { //Otherwise just for this category
                  localizedItemName = i18n.$t('report.create.additional_comments');
                }
              }

              let localizedItem = localizeSingleField(item, i18n);

              return {
                ...localizedItem,
                display_name: localizedItemName, //TODO Could be translated here, if not already translated before. Create a util function for translating! Also for unit!
              }
            }
          });

          report.fields = fields;

          if (pdf.value != null){
            pdf.value.attachReport(report);
          } else {
            pdf.value = new PdfReport(i18n, qrInstance, report);
          }

          //Wait for processing to finish
          await pdf.value.promise;
        }

        pdfBlob.value = pdf.value.export();
      }

      //Return already created PDFs
      return pdfBlob.value;
    }

    const downloadPDF = function(){
      isProcessingPDF.value = true;
      createPDF()
        .then((blob) => download(i18n, sanitizeFileOrFolderName(`${pdfFileName.value}.pdf`, '_'), blob, blob.type, getPlatforms()))
        .catch((error) => {
          console.error(error);
          return; //TODO No error handling?
        })
        .finally(() => isProcessingPDF.value = false);
    }

    const downloadZIP = function(){
      isProcessingZIP.value = true;
      createZIP.value()
        .then((blob) => download(i18n, sanitizeFileOrFolderName(`${zipFileName.value}.zip`, '_'), blob, blob.type, getPlatforms()))
        .catch((error) => {
          console.error(error);
          return; //TODO No error handling?
        })
        .finally(() => isProcessingZIP.value = false);
    }

    //TODO Add function to create CSV

    return { i18n, closeModal, reportFiles, anyReportsHaveFiles, isProcessingPDF, isProcessingZIP, processingFiles, isProcessingAnyFile, reportsContainUpdatedOnes, getLocalizedReportTypeName, getAnimalName, getReportTimestampString, downloadPDF, downloadZIP, downloadFile, zip, informationCircleOutline };
  }
});

export async function openReportExportModal(component, reportIDs, reportProperties, reportMetadata, reportsData){
  if (component != null && reportIDs != null && Array.isArray(reportIDs)) {
    const modal = await modalController
      .create({
        component,
        componentProps: {
          reportIDs,
          reportProperties,
          reportMetadata,
          reportsData
        },
      })
    return modal.present();
  }
}

export default ReportExportModal;
</script>

<style scoped>
.file-button {
  text-transform: none;
  white-space: normal;
  height: auto;
  --padding-top: 10px;
  --padding-bottom: 10px;
}

.main-button {
  font-weight: bold;
}

.download-button-container {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  flex-wrap: wrap;
}

.single-files-header {
  width: 100%;
  text-align: center;
  padding-left: 10px;
  padding-right: 10px;
  box-sizing: border-box;
}

.single-files-header > h3 {
  padding-top: 15px;
  padding-bottom: 15px;
  border-top: 1px solid var(--ion-color-medium);
}

.information-container {
  display: flex;
  flex-flow: row;
  align-items: center;
  padding-right: 10px;
}

.information-container > * {
  opacity: 1!important;
  padding: 10px 0px 10px 10px;
}

.information-container ion-icon {
  min-width: 24px;
  max-width: 24px;
}

</style>