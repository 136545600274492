<template>
  <span class="encyclopedia-link">
    <a v-if="entryFound" @click="openEncyclopediaEntry">
      {{ name }}
      <ion-icon class="open-icon" :icon="openOutline"></ion-icon>
    </a>
    <template v-else>
      {{ name }}
    </template>
  </span>
</template>

<script>
import { IonIcon } from '@ionic/vue';
import { computed, watch, ref, defineComponent } from 'vue';

import { useStore } from 'vuex';

import { useI18n } from "@/utils/i18n";

import { openOutline } from 'ionicons/icons';

import { openEncyclopediaModal, default as encyclopediaModalComponent } from '@/components/EncyclopediaModal.vue';

import _ from 'lodash';

export default defineComponent({
  components: { IonIcon },
  props: {
    id: String,
    descriptor: String
  },
  setup(props) { //TODO Could open a small tooltip on hover with short description!
    const i18n = useI18n();
    const store = useStore();

    //Use the given encyclopediaId or the descriptor to find the entry
    const encyclopediaEntry = ref(null);

    const entryFound = ref(false);

    const NOT_FOUND_PLACEHOLDER = '\u2014'; //Long dash

    watch([
           i18n.locale,
           () => store.getters['encyclopedia/getEncyclopediaEntryByIdentifier'],
           () => store.getters['encyclopedia/getEncyclopediaEntryByDescriptor'],
           () => props.id,
           () => props.descriptor], ([locale, identifierMapping, descriptorMapping, id, descriptor]) => {
      //Anonymous async function that gets called immediately to load
      (async () => {
        let entry;
        //First try identifier as it is more reliable
        if (id != null) {
          entry = await identifierMapping(id);
        }
        //Otherwise try to find through descriptor
        if (descriptor != null && entry == null) {
          entry = await descriptorMapping(locale, descriptor);
        }

        if (entry != null) {
          encyclopediaEntry.value = entry;
          entryFound.value = true;
        } else {
          encyclopediaEntry.value = null;
          entryFound.value = false;
        }
      })();
    }, { immediate: true});

    const name = computed(() => {
      if (encyclopediaEntry.value != null && encyclopediaEntry.value.translations != null && i18n.locale.value in encyclopediaEntry.value.translations) {
        let translation = encyclopediaEntry.value.translations[i18n.locale.value];
        if (translation.descriptor != null) return translation.descriptor;
      }
      if (props.descriptor != null) return _.startCase(props.descriptor); //Capitalize descriptor as a fallback, if given
      return NOT_FOUND_PLACEHOLDER;
    })

    const openEncyclopediaEntry = function() {
      if (encyclopediaEntry.value != null) openEncyclopediaModal(encyclopediaModalComponent, encyclopediaEntry.value.identifier);
    }

    return { i18n, encyclopediaEntry, name, entryFound, openEncyclopediaEntry, openOutline }
  }
});
</script>

<style scoped>
.encyclopedia-link {
  cursor: pointer;
  color: var(--ion-color-primary-text);
  font-weight: bold;
}

.encyclopedia-link:hover a {
  text-decoration: underline;
}

.open-icon {
  position: relative;
  vertical-align: text-top;
  padding-top: 0.1em;
} 
</style>
